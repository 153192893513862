import SUPPLIER_DATA from "@/assets/json/supplier.json";

import {
  ColDef,
  ColGroupDef,
  GridApi,
  ColumnApi,
  GridOptions,
} from "ag-grid-community";

import { ref, onMounted, computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useTimeAgo } from "@vueuse/core";
import * as Yup from "yup";
import Dropzone from "dropzone";
import {
  formatNumbers,
  formatDates,
  translate,
  currentLang,
  setDateFormat,
} from "@/core/helpers/config";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { ElMessageBox, ElMessage } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const gridApi = ref<GridApi>();

export const SupplierSetup = () => {
  const suppliersData = ref();
  const rfqRows = ref<any[]>([]);
  const rfqDocs = ref<any[]>([]);
  const rfqComments = ref<any[]>([]);
  const store = useStore();
  const loading = ref(true);
  const colApi = ref<ColumnApi>();
  const selectedUsers = ref<any[]>([]);
  const componentKey = computed(() => store.state.BodyModule.componentKey);
  const sortedComments = computed(() => {
    if (rfqComments.value.length === 0) return [];
    return rfqComments.value.sort((a, b) => {
      return (
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
      );
    });
  });
  const currentUser = computed(() => {
    return store.getters.currentUser;
  });

  const customColumnDefs = ref<(ColGroupDef | ColDef)[]>([
    {
      field: "id",
      colId: "id",
      headerName: "Id",
      hide: true,
      editable: false,
    },
    {
      field: "name",
      colId: "name",
      headerName: translate("rfq.name"),
      editable: false,
    },
    {
      headerName: translate("rfq.supplierPage.requestHeader"),
      marryChildren: true,
      children: [
        {
          field: "request_unit",
          colId: "request_unit",
          headerName: translate("rfq.unit"),
          editable: false,
        },
        {
          field: "request_qty",
          colId: "request_qty",
          headerName: translate("rfq.quantity"),
          editable: false,
          tooltipValueGetter: (params) =>
            params.data.request_qty_hide
              ? translate("rfq.supplierPage.hiddenQtyMsg")
              : params.value,
          cellClassRules: {
            "fst-italic": (params) => params.data.request_qty_hide,
          },
          cellRenderer: (params) =>
            params.data.request_qty_hide
              ? 'hidden <i class="bi bi-info-circle"></i>'
              : params.value,
          valueFormatter: (params) =>
            params.data.request_qty_hide
              ? translate("rfq.supplierPage.hidden")
              : params.value,
        },
      ],
    },
    {
      headerName: translate("rfq.supplierPage.responseHeader"),
      marryChildren: true,
      children: [
        {
          field: "response_unit",
          colId: "response_unit",
          headerName: translate("rfq.unit"),
          editable: true,
          cellStyle: {
            "background-color": getCSSVariableValue("--bs-light-success"),
          },
          cellClass: ["text-grey"],
        },
        {
          field: "response_price",
          colId: "response_price",
          headerName: translate("rfq.price"),
          editable: true,
          cellStyle: {
            "background-color": getCSSVariableValue("--bs-light-success"),
          },
          cellClass: ["text-grey"],
        },
        {
          field: "response_qty",
          colId: "response_qty",
          headerName: translate("rfq.quantity"),
          editable: true,
          cellStyle: {
            "background-color": getCSSVariableValue("--bs-light-success"),
          },
          cellClass: ["text-grey"],
        },
        {
          colId: "response_total_revenue",
          headerName: translate("rfq.supplierPage.totalRevenue"),
          editable: false,
          valueGetter: (params) =>
            Number(
              +params.data["response_price"] * +params.data["response_qty"]
            ).toFixed(2),
        },
        {
          field: "comments",
          colId: "comments",
          wrapText: true,
          headerName: translate("rfq.comments"),
          editable: true,
          cellStyle: {
            "background-color": getCSSVariableValue("--bs-light-success"),
          },
          cellClass: ["text-grey"],
          cellEditor: "agLargeTextCellEditor",
          tooltipValueGetter: (params) => params.value,
        },
      ],
    },
  ]);

  // Form Stuff

  const rfqForm = reactive({
    rfqNo: "",
    rfqClientName: "",
    rfqClientContact: "",
    rfqClientEmail: "",
    rfqDescription: "",
  });

  const validationSchema = Yup.object().shape({
    rfqNo: Yup.string()
      .required()
      .label("No."),
    rfqClientName: Yup.string()
      .required()
      .label("Client Name"),
    rfqClientContact: Yup.string()
      .required()
      .label("Client Contact"),
    rfqClientEmail: Yup.string()
      .required()
      .label("Client Email"),
    rfqDescription: Yup.string()
      .required()
      .label("Description"),
  });

  const initialValues = ref();

  const handleSupplierFormAccept = (evt) => {
    console.log(evt);
    console.log(rfqForm);
  };

  const handlesupplierFormDecline = (evt) => {
    console.log(evt);
    console.log(rfqForm);
    ElMessageBox.confirm(
      `Are you sure you want to decline the offer?`,
      "Warning",
      {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    ).then(() => {
      ElMessage({
        type: "success",
        message: "Offer Declined!",
      });
    });
  };

  const initDropzone = async () => {
    const myDropzone = new Dropzone(`div#kt_dropzone_rfq`, {
      url: "https://618d426bfe09aa001744064a.mockapi.io/files",
      maxFiles: 10,
      createImageThumbnails: false,
      addRemoveLinks: false,
      autoProcessQueue: false,
      disablePreviews: false,
    });
  };

  // Pretty much returns how long ago a comment was
  const handleTimeAgo = (date) => {
    return useTimeAgo(date).value;
  };

  const handleAddComment = (evt) => {
    rfqComments.value.push({ ...evt });
  };

  const bodyOptions = reactive<GridOptions>({
    editType: "",
    rowSelection: "multiple",
    enableRangeSelection: false,
    enableFillHandle: false,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    valueCacheNeverExpires: true,
    onGridReady: (params) => {
      gridApi.value = params.api;
      colApi.value = params.columnApi;
    },
  });

  onMounted(() => {
    setTimeout(() => {
      initDropzone();
      suppliersData.value = SUPPLIER_DATA["data"];
      rfqRows.value = SUPPLIER_DATA["data"]["rfqRows"];
      rfqDocs.value = SUPPLIER_DATA["data"]["documents"];
      rfqComments.value = SUPPLIER_DATA["data"]["comments"];
      initialValues.value = {
        rfqNo: suppliersData.value["rfq_id"],
        rfqClientName: suppliersData.value["client_name"],
        rfqClientContact: suppliersData.value["client_contract"],
        rfqClientEmail: suppliersData.value["client_email"],
        rfqDescription: suppliersData.value["description"],
      };
      loading.value = false;
    }, 1500);
  });

  return {
    ...toRefs(rfqForm),
    translate,
    handleAddComment,
    currentUser,
    handleTimeAgo,
    sortedComments,
    handleSupplierFormAccept,
    handlesupplierFormDecline,
    validationSchema,
    gridApi,
    colApi,
    loading,
    bodyOptions,
    setDateFormat,
    customColumnDefs,
    componentKey,
    currentLang,
    suppliersData,
    rfqRows,
    rfqDocs,
    rfqComments,
    selectedUsers,
    initialValues,
  };
};
